/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Tengo desde hace unos dias un portátil, es bastante viejo pero me va a ser util, el problema que tiene es que se calienta demasiado y a veces se reinicia solo, por eso he decidido construirle una base para refrigerarlo. Lo primero antes de ponerme a cortar a la ligera es tener claro lo que quiero y como lo voy a hacer. Asique estas van a ser las especificaciones de mi base:"), "\n", React.createElement(_components.p, null, "Materiales reciclados: Siempre es bueno reciclar y tampoco me voy a gastar mucho dinero en esta base."), "\n", React.createElement(_components.p, null, "Material metalico: Estaria bien de aluminio pero va a ser dificil de encontrar y caro, puede que lo haga de chapa de acero."), "\n", React.createElement(_components.p, null, "Sensor de temperatura: Para poder regular los ventiladores. Puedo utilizar un lm35."), "\n", React.createElement(_components.p, null, "Dos ventiladores: Uno colocado debajo de la ram y otro del procesador."), "\n", React.createElement(_components.p, null, "Hub USB: El problema de mi portatil es que tiene solo dos USB y muy juntos, ademas son 1.1, pero bueno es lo que hay."), "\n", React.createElement(_components.p, null, "Alimentación por USB: Lo mas sencillo."), "\n", React.createElement(_components.p, null, "Rohebus: Para controlar manualmente los ventiladores."), "\n", React.createElement(_components.p, null, "Velocidad controlada de los ventiladores: Por PWM"), "\n", React.createElement(_components.p, null, "Tres modos de control de los ventiladores: Automatico, manual y desde el PC."), "\n", React.createElement(_components.p, null, "Control por PIC: Con comunicación con el PC por USB."), "\n", React.createElement(_components.p, null, "Por ahora se me ha ocurrido esto, parece un proyecto completo, ahora a por el siguiente paso; el croquis."), "\n", React.createElement(_components.p, null, "Un saludo"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
